export const getValue = (dict,key,defaults) =>
{
    if (key in dict)
        return dict[key];
    return defaults;
}
export const saveFile = (blob,filename) => 
{
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
}
export const getMulValues = (dict,keys,defaults) =>
{
    let val = dict;
    for(let i=0;i<keys.length;i++)
    {
        if (!(keys[i] in val))
            return defaults;
        val = val[keys[i]];
    }
    return val
}
export const PDF_FILE = 'pdf';
export const CSV_FILE = 'csv';
export const TABLE_FILES = [CSV_FILE,
    "xls"   ,
    "xlsx"   ,
    "xlsm"   ,
    "xlsb"   ,
    "odf"   ,
    "ods"   ,
    "odt"   ,
    "tab"
    ]
export const ALL_OUTPUT_VALUE = "";
export let yearAry  = ["Half","Quarter","Month"]
let Nodeurl = "";
let pythonUrl = "";
let authURL = "";
let currUrl = "";
export const compRegenMsg = (
    <>BitSonic will regenerate the Standard Outputs.
    <br/>This can take a little time<br/>
    Are you sure?</>
);
export const compRegenMsgCurrency = (
    <>BitSonic will regenerate the Standard Outputs.
    <br/>This can take a little time<br/>
    <br/><b>And It will erase everything</b><br/>
    Are you sure?</>
);
if (window.location.href.includes("localhost")) {
    currUrl = "http://localhost:3000";
    Nodeurl = "http://localhost:8090"
    pythonUrl = "http://127.0.0.1:5100";
    authURL = "http://localhost:3001"
}
else if(window.location.href.includes("https://portal-qa.bitsonic.ai"))
{
    currUrl = "http://portal-qa.bitsonic.ai";
    Nodeurl      = "https://api.bademeister-jan.pro:453"
    pythonUrl    = "https://api.bademeister-jan.pro:338";
    authURL = "http://auth.bitsonic.ai"

}
else
{
    currUrl = "http://portal.bitsonic.ai";
    Nodeurl      = "https://api.bademeister-jan.pro:453"
    pythonUrl    = "https://api.bademeister-jan.pro:336";
    authURL = "http://auth.bitsonic.ai"

}
export {currUrl, Nodeurl, pythonUrl, authURL,}
export const ApiCall = async (apicall,parameters,setCookie,raiseError= (err)=>{console.log(err)}) =>
{
    let res = undefined;
    await fetch(
        apicall,
        parameters
    )
    .then((data) =>
    {
        if(data.status === 500)
            raiseError(data.statusText);
        else if (data.status === 401)
        {
            console.log(authURL);   
            setCookie("redirection",currUrl,{"domain" : currUrl.includes("bitsonic") ? ".bitsonic.ai" : "localhost"});
            alert("re-login required");
            window.location.href = authURL; 
        }
        else
        {
            const dataHeaders = Object.fromEntries(data.headers);
            if(dataHeaders.jwt !== undefined)
                setCookie("jwt",dataHeaders.jwt,{"domain" : currUrl.includes("bitsonic") ? ".bitsonic.ai" : "localhost"});
            res = data;
        }
    }).catch((err) => raiseError(err));
    return res;
}
export const SORTED_DATA = "sorted_data";
export const HTTP_200_OK = 200
export const firstOutputTitle = {
    "First" : [],
    "YearPeriod": "",
    "Royalty" : "",
    "theYear" : 1,
    "split" : [],
    "dateStatement" : "",
    "yearStatementMethod" : ""
}
export const repoTab = 'Repository';
export const AllSet = {"All Output" : "normal","Catalogue All Output" : "CatalogTabs","Budget" : "BudgetDetails" };

export const summarised_yearly_clients = ['Dynamic BETA','Dynamic Music','Client']
export const budget_users = ['']
export const CATALOG_TABLE_TYPES = ['budget','underwritten']
export const CATALOG_TABLE_TYPES_ICON = ['fa-solid fa-money-bill','fa-solid fa-underline']
export const CATALOG_TABLE_DETAILS = 'details'
export const CATALOG_TABLE_NPS = 'NPS'
export const CATALOG_TABLE_GROSS = 'Gross'

export const CATALOG_TABLE_TEXT = ['Genre','ERA']
export const CATALOG_TABLE_DATE = ['Acquisition Date','Acquisition Completion Date']
export const CATALOG_TABLE_ROYALTY = ['Base NPS',"Underwritten Net NPS"]
export const number_regex = /^\d{1,3}(,\d{3})*(\.\d+)?$|^\d+(\.\d+)?$/
////!!!!!!!!!!!!!!!!!!!!need fix!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
export const getDictFromTabObj = (objectlist,keys,values) =>
{
    let output = {};
    for(let i = 0;i<objectlist.length;i++)
    {
        for(let j = 0;j<keys.length;j++)
        {
            if(objectlist[i][keys[j]] !== undefined)
                output[objectlist[i][keys[j]]] = objectlist[i][values[j%(values.length)]]
        }   
    }
    return output;
}
export const getDictFromTabObjList = (objectlist,keys,values) =>
{
    let output = {};
    for(let i = 0;i<objectlist.length;i++)
    {
        for(let j = 0;j<keys.length;j++)
        {
            if(objectlist[i][keys[j]] !== undefined)
            {
                for(let f = 0;f<keys[j].length;f++)
                {
                    output[objectlist[i][keys[j]][f]] = objectlist[i][values[j%(values.length)]]
                }
            }
        }   
    }
    return output;
}
export const getListFromTabObj = (objectlist,values) =>
{
    let output = [];
    for(let i = 0;i<objectlist.length;i++)
    {
        for(let j = 0;j<values.length;j++)
        {
            if(objectlist[i][values[j]] !== undefined)
                output.push(objectlist[i][values[j]])
        }   
    }
    return output;
}


export let specialRev = ["Share","Recieved","Control"];

export const CurrencyDetails = "CurrencyDetails";

export const shortToRead = {
    'Song'  : 'Song Name' ,
    'Inc' : 'Income Type'       ,
    'IncII' : 'Income Type II'     ,
    'Inc II' : 'Income Type II'     ,
    "Artist" : "Release Artist"  ,
    "Contract" : "Payor Contract Number",
    "Src" : "Source",
    "Sng" : "Song",
    "Prd" : "Product"
}
export const readToshort = {
    'Song Name'  : 'Song' ,
    'Income Type' : 'Inc'       ,
    'Income Type II' : 'IncII'     ,
    "Release Artist" : "Artist"  ,
    "Release Year" : "Release Year"  ,
    "Release Date" : "Date",
    "Payor Contract Number" :"Contract",
    "Src" : "Source",
    "Sng" : "Song",
    "Prd" : "Product"
}

export const titledict = {"Song_Name_9LC": "Song Name", "Release_Artist_9LC": "Release Artist", "Third_Party_9LC": "Payor",
"Normalized_Income_Type_9LC": "Income Type", "Adjusted_Royalty_USD_SB": "NET Royalty","Adjusted_Payee_Gross_Royalty_USD_Amount_SB": "GROSS Royalty",
"Adjusted_Bonus_Royalty_USD_SB": "BONUS Royalty", "Release_Date_9LC": "Date",
"Normalized_Income_Type_II_9LC": "Income Type II", "Normalized_Source_9LC": "Source",
"Normalized_Country_9LC": "Country", "Year_Statement_9LC": "Year", "Half_Statement_9LC": "Half","Contract_ID_9LC" : "Contract",
"Month_Statement_9LC": "Month","Quarter_Statement_9LC": "Quarter","Source_Product_Number_SB" : "Product"}

export const INCOME_TYPE_TAB = "Income Type x Rev x Period"

export function getKeyFromValue(obj, valueToFind)
{
    for (const key in obj) {
      if (obj[key] === valueToFind)
        return key;
    }
    console.log(obj,valueToFind);

    return null;
}

export const defultOkButton = {"Generate"  : false,"Download" : false,"DownloadStandard" : false,"DeleteOutput" : false,"GetOutput" : false}
export const GetDate = (date) => {return date.toLocaleDateString('en-GB', { day : "numeric",month:"short" ,year:"numeric"});/*(date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear();*/}
export const GetDateAndTime = (date) => {return date.toLocaleDateString('en-GB', { day : "numeric",month:"short" ,year:"numeric"})+" "+ date.toLocaleTimeString('en-GB',{  timeZoneName: "short" });/*(date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear();*/}
                      //#0                  1                 2              3        4           5             6
export let titleColumns = ["Catalogue Name" , "Catalogue Date" , "Last Modified","Payor","Accounts", "Line Count","Currency",
                       //      7           8               9          10         11               12               13
                     "Nominal Rev", "Adjusted ($)", "Adjusted ","Files","Number Of Songs","Total Revenue($)","Total"]

export let OVERVIEW_TITLE = ["Output Summary","Statement Catalogue Summary","Cash Date Catalogue Summary","Payor Summary","Payor Account Summary"]

export let simpleFirstSelect =                [
                    {"value" : getValue(readToshort,"Song Name","Song Name"),"label" : "Song Name"},
                    {"value" : getValue(readToshort,"Income Type","Income Type"),"label" : "Income Type"},
                    {"value" : getValue(readToshort,"Source","Source"),"label" : "Source"},
                    {"value" : getValue(readToshort,"Artist","Artist"),"label" : "Artist"},
                    {"value" : getValue(readToshort,"Territory","Territory"),"label" : "Territory"}]
                    
export const getTabDetails = (dicti,cataName,tabName,target,specialDictTab,OgDict) =>
{
    let ary = tabName in OgDict ? [specialDictTab[OgDict[tabName]],"details"] : ["normal","tabs",tabName];

    if(target !== "")
        return getValue(getMulValues(dicti[cataName],ary,{}),target,undefined);
    else
        return getMulValues(dicti[cataName],ary,{});
}
export const getUserPath = (defualt,draftValue,GlobaldraftValue,OrigRenme,theCatalog,outputName,specialDictTab) =>
{
    const isSpecial = outputName in OrigRenme[theCatalog] || theCatalog === "";
    const value = getValue(specialDictTab,OrigRenme[theCatalog][outputName],"normal");
    if(value.includes("GlobalDraft") && isSpecial)
        return GlobaldraftValue;
    else if((value.includes("Draft") && isSpecial) || value === "normal")
        return draftValue;
    else
        return defualt;
    
}

